'use client';

import GenericImage from './components/elements/GenericImage/GenericImage';
import ErrorDTMEvent from './components/ErrorDTMEvent/ErrorDTMEvent';

interface ErrorProps {
  error: Error & { digest?: string };
  reset: () => void;
}

const GlobalError = ({ error }: ErrorProps) => {
  console.error(error);

  return (
    <>
      <div
        style={{ height: '100vh' }}
        className="d-flex justify-content-center mt-3"
        data-att="global"
      >
        <div className="mr-2">
          <GenericImage
            alt="404 Page"
            className="visible-sm "
            src="https://img.lakeshorelearning.com/is/image/OCPreproduction/oops-girl?wid=640&amp;qlt=85,1&amp;fmt=gif&amp;pscan=auto"
            width="320"
            height="420"
          />
        </div>
        <div style={{ marginLeft: '50px' }}>
          <p
            style={{
              letterSpacing: '-.015em',
              color: '#dd3e49',
              fontSize: '80px',
              fontWeight: 'bold',
              lineHeight: '100px',
              marginBottom: '20px',
            }}
          >
            Oops!
          </p>
          <p
            style={{
              letterSpacing: '-0.015em',
              color: '#020202',
              fontSize: '18px',
              lineHeight: '27px',
              marginBottom: '20px',
            }}
          >
            Sorry! We can&rsquo;t seem to find the page you&rsquo;re looking
            for.
          </p>
          <p
            style={{
              letterSpacing: '-0.015em',
              color: '#020202',
              fontSize: '18px',
              lineHeight: '27px',
            }}
          >
            Please{' '}
            <a className="blue" href="/" data-updated="false">
              click here
            </a>{' '}
            to continue.
          </p>
        </div>
      </div>
      <ErrorDTMEvent />
    </>
  );
};

export default GlobalError;
